import React from 'react'
import {LocaleLink} from '../helpers/locale'
import classnames from 'classnames'

export default function CasestudyGrid ({casestudies}) {

  return (
    <section className="casestudy-grid">
      {casestudies.map((casestudy, i) => (
        <LocaleLink key={i} to={casestudy.url}>
          <div className={classnames({'casestudy-grid__item': true, 'casestudy-grid__item--dark-text-on-hover': casestudy.darkTextOnHover})} style={{'--hover-background-colour': casestudy.hoverColour || ''}}>
            <div className="casestudy-grid__image" style={{backgroundImage: `url(${casestudy.imageUrl})`}}></div>
            <div className="casestudy-grid__text">
              <h1>{casestudy.title}</h1>
              {casestudy.jobName && <h2>{casestudy.jobName}</h2>}
              {casestudy && (
                <aside className="casestudy-grid__tag">
                  <h2>{casestudy.tag}</h2>
                  {casestudy.description}
                </aside>
              )}
            </div>
          </div>
        </LocaleLink>
      ))}
    </section>
  )
}
