import React from 'react'
import Layout from '../templates/layout'
import CasestudyGrid from '../components/casestudy-grid'
import Content from '../components/content'
import PageHelmet from '../components/page-helmet'
import {graphql} from 'gatsby'
import {urlFor, unblockPortableText} from '../helpers/sanity'
import {localiseObject} from '../helpers/locale'
import {PAGE_URLS, SITE_URL} from '../helpers/constants'
import '../scss/pages/index.scss'

export const query = graphql`

  query CasestudiesPageQuery {
    allSanityCaseStudyPage(sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          id
          title {
            en
            fr
          }
          jobName
          client
          _rawIntro
          _rawReferenceImage
          slug {
            current
          }
          tags {
            en
          }
        }
      }
    }
    sanityCaseStudiesRootPage {
      id
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
      _rawIntro
    }
  }

`

function Casestudies ({data: {allSanityCaseStudyPage: {edges}, sanityCaseStudiesRootPage: {_rawIntro, pageSeo}}}) {

  const casestudies = edges.map(({node}) => node).map(({client, tags, jobName, _rawIntro, _rawReferenceImage, slug: {current}}) => {

    return {
      imageUrl: urlFor(_rawReferenceImage.asset),
      title: client,
      jobName: jobName,
      description: unblockPortableText(localiseObject(_rawIntro)),
      url: `/${PAGE_URLS.CASESTUDIES}/${current}`,
      tag: (tags ? localiseObject(tags) : []).join(' + ')
    }
  })

  return (
    <Layout fixHeader={true} topPadding={10}>
      <PageHelmet
        url={`${SITE_URL}/casestudies`}
        pageSeo={pageSeo}
      />
      <Content content={_rawIntro} />
      <CasestudyGrid casestudies={casestudies}/>
    </Layout>
  )
}

export default Casestudies
